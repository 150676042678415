import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo/seo"
import Banner from "../../components/banner/banner"
import Links from "../../components/services/links/links"

export default function Family({ data }) {
  return (
    <Layout>
      <SEO title="Family Law" />
      <Banner
        image={
          <Img
            fluid={data.placeholderImage.childImageSharp.fluid}
            style={{ height: "100%" }}
          />
        }
        title="Services"
      />
      <div className="width75">
        <h1 className="title">Family Law</h1>
        <Links type="about" />
        <div className="ribbon-top">
          <p>
            A relationship breakdown can be an emotional and difficult time for
            anyone involved. We can offer a new low-conflict option for dispute
            resolution in family matters. This offers a better, low-stress
            solution to negotiating a more amicable end to a relationship, which
            will benefit you and your children. At JJ Law Chambers we can help
            with the complex issues, which may arise from family breakdown,
            whether financial or child-related. We take utmost care and firstly
            try to encourage communication among the individuals involved and
            keep careful considerations of the whole family most particularly
            the children.
          </p>
          <p>
            Separation and Divorcing can be legally complex process. We try to
            avoid court proceedings wherever possible by having mediation,
            collaborative law and round table meetings. Our team is committed to
            seeking negotiated solutions without compromising on our client’s
            position.
          </p>
          <p>
            Whether you are married or in a civil partnership and your
            relationship has broken down, our Family Law team can provide you
            with the best legal advice on separation agreements and divorce. Our
            specialist team of family solicitors will look at the whole
            financial picture including the family home, maintenance and
            pensions to help you reach the best possible outcome for you so you
            can start your future.
          </p>
          <p>
            If you need advice working out an agreement with your former partner
            or require a solicitor to represent you, JJ Law Cambers have
            experienced solicitors to deal with these types of matters.
          </p>
          <p>
            Whatever your circumstances, our expert team always aim for the best
            possible outcome. We ensure you feel guided and supported throughout
            the process.
          </p>
          <div className="center">
            <p>A wide experience on all aspects of Family Law, such as:</p>
            <ul>
              <li>Divorce and Relationship Breakdown</li>
              <li>Pre-nuptial and post-nuptial arrangements</li>
              <li>Cohabitation agreements</li>
              <li>Separation agreements</li>
              <li>Injunctions</li>
              <li>Financial matters</li>
              <li>Child arrangements</li>
              <li>Property after separation</li>
              <li>Change of name deeds</li>
            </ul>
          </div>
          <Img
            fluid={data.bottomImage.childImageSharp.fluid}
            style={{ height: "150px" }}
          />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "tower-bridge.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bottomImage: file(relativePath: { eq: "family-law.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
